import React, {
  useState,

  forwardRef,

  useEffect,
} from "react";

import { Row, Col, Container, Form, Button } from "react-bootstrap";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  WIexportreport,
  getListofProjectno,
  getWIdataexcelbyProjectno

} from "../../Services/WI-services";

import { SearchSelectWIProject } from "../utility/search-select";


import { ExportToExcel } from "../PRODTransaction/PRODWI_Excel";
import { Date_Input } from "../utility/CommonComponents/Date_Component";
import { todayDate } from "../utility/CommonComponents/TodayDate";


const List_Of_Welders = forwardRef((props, ref) => {
  let formName = "WI Slip";
  const [widata, setwidata] = useState(null);
  const [reload, setReload] = useState(false);
  const [getreport, setgetreport] = useState([]);
  const [ProjectDetails, setProjectDetails] = useState([]);

  // const [loader, setloader] = useState(false);

  const [normalstyle, setNormal] = useState({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "var(--bg-alt-2)",
    }),
  });

  let Data = [];


  const [wiobj, setwiobj] = useState({
    WI_Project_No: "",
    WI_Joint_No: "",
    WI_Welder_Id: "",
    WI_From_Date: "",
    WI_To_Date: "",
    WI_Slip_Status: "",
  });

  const [error, setError] = useState({
    WI_Project_No: {},
    WI_Joint_No: {},
    WI_Welder_Id: {},
    WI_From_Date: {},
    WI_To_Date: {},
    WI_Slip_Status: {},
  });

 
  var Slipstatus = [
    { value: "Created", name: "Created" },
    { value: "Cancelled", name: "Cancelled" },
    { value: "Deleted", name: "Deleted" },
  ];
  let reporName = "WelderListReport";

  
 const fetchwidata = async (projectno,jointno) => {
  if(wiobj.WI_Project_No && wiobj.WI_Joint_No !== undefined){
    const result = await getWIdataexcelbyProjectno(projectno,jointno);
    setwidata(result);
  }
  };

  const fetchListofProject = async () => {
    const result = await getListofProjectno();
    setProjectDetails(result);
  };

  useEffect(() => {
    // fetchProject();
    fetchListofProject()
  }, []);

  // useEffect(() => {
  //   fetchwidata();
  // }, [welderid]);

  useEffect(() => {
    if (reload) {
      setReload(false);
    }
  }, [reload]);


  const uniqueProjectNumbers = [
    ...new Set(ProjectDetails && ProjectDetails.map((item) => item.SK)),
  ];


  const getJointNosForSelectedProject = () => {

    if (wiobj.WI_Project_No && ProjectDetails && ProjectDetails.length > 0) {
      const selectedProjects = ProjectDetails.filter(
        (item) => item.SK === wiobj.WI_Project_No
      );
      

      const uniqueJointNos = 
        selectedProjects
          .map((project,id) => project.JointNumbers.values)
        

let abc = uniqueJointNos[0]
      

      if (uniqueJointNos.length > 0) {
        return Array.from(abc).map((jointNo) => ({
          label: jointNo,
          value: jointNo,
        }));
      }
    }
    return [];


  };

  const getWelderForSelectedProject =  () => {

    if (
      wiobj.WI_Project_No &&
      wiobj.WI_Joint_No &&
      widata &&
      widata.length > 0
    ) {
     

      const removeDuplicates = (keyFn, array) => {
        var mySet = new Set();
        return array.filter(function(x) {
            var key = keyFn(x), isNew = !mySet.has(key);
            if (isNew) mySet.add(key);
            return isNew;
        });
      }

     
        const uniqueWelders = removeDuplicates(x => x.WI_Welder_Id, widata);
        return uniqueWelders.map((welderId) => ({
          label: welderId.WI_Welder_Id,
          value: welderId.WI_Welder_Id,
        }));
      
    }

    return [];
  };

  const validate = async (e) => {
    
    var isError = false;

    if (wiobj.WI_From_Date !== "" && wiobj.WI_To_Date !== "") {
      if (wiobj.WI_From_Date > wiobj.WI_To_Date) {

        isError = true;
        error.WI_From_Date = {
          status: true,
          // message:"Selected date cannot be less than From date"
        };
        error.WI_To_Date = {
          status: true,
          // message:"Selected date cannot be less than From date"
        };
        toast.warning("ToDate Should be Greater than or Equal to FromDate", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }
    }

    return isError;
  };

  const handleSubmit = async (e) => {
  
    if (!(await validate())) {
      if (wiobj.WI_From_Date === "") {
        // alert("Please Add Project No")
        toast.warning("Please select From Date", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }

      if (wiobj.WI_To_Date === "") {
        // alert("Please Add Project No")
        toast.warning("Please select To Date", {
          position: toast.POSITION.TOP_LEFT,
          className: "toast-message",
        });
      }

      if (wiobj.WI_From_Date !== "" && wiobj.WI_To_Date !== "" ) {
        const result = await WIexportreport(wiobj);
        setgetreport(result);
        let sheetName = "Report_ListofWelders";

        let filename = "List of welders used in project";
        if (result?.length > 0) {
          result.forEach((row) => {
            Data.push({
              A: "",
              B: row.WI_Project_No,
              C: row.WI_SlipDate,
              D: row.WI_plant,
              E: row.WI_Slip,
              F: row.WI_Slip_Status === "Cancelled" ? "Yes" : "No",
              G: row.WI_Joint_No,
              H: row.WI_WPS_No,
              I: row.WI_JointDesign === "Tubesheet" ? row.WI_Process : (row.WI_ProcessCategory || "").split("#")[0],
              J: row.WI_Type,
              K: row.WI_Welder_Id,
              L: row.WI_Welder_Name,
            });
            console.log("Dataaaaa", Data);
          });

          let titleCell = "E3";
          let titleWelderRange = "G1:Y5";
          let tbodyHeadRange = "A6:L6";
          let tbodyRange = "A7";
          let blankRow = "A5:L5";
          let width = [
            { col: "A", width: 2.5 },
            { col: "B", width: 13.7 },
            { col: "C", width: 13.7 },
            { col: "D", width: 13.7 },
            { col: "E", width: 8.5 },
            { col: "F", width: 9.5 },
            { col: "G", width: 16.5 },
            { col: "H", width: 20 },
            { col: "I", width: 13.7 },
            { col: "J", width: 13.7 },
            { col: "K", width: 15 },
            { col: "L", width: 18 },
          ];
          let Headings = [
            { col: "G2", value: "LIST OF WELDERS USED IN PROJECT" },
            {
              col: "C4",
              value:
                "1.One row displays data for one slip number for entered filter. Slip number is unique.",
            },
          ];

          ExportToExcel(
            filename,
            width,
            result,
            Data,
            titleCell,
            titleWelderRange,
            tbodyHeadRange,
            tbodyRange,
            blankRow,
            Headings,
            sheetName,
            reporName
          );
        } else {
          toast.info("There are no records matching to filters.", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }

        if (result.length > 0) {
          // alert("Data Exported")
          toast.success("Exported successfully", {
            position: toast.POSITION.TOP_LEFT,
            className: "toast-message",
          });
        }
      }

     
    }

  };

  return (
    <>
      <Container fluid>
        <Row className="RowHeight mb-4 ml-2">
          <Col md={12} sm={12} xs={12} className="ListTitle">
            List of Welders Used in Project Filters
          </Col>
        </Row>
        <div className="px-2 pb-2">
          <Row className="gx-3" style={{ marginBottom: "2rem" }}>
            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    // className="inspdrop"
                    id="WI_Project_No"
                    name="WI_Project_No"
                    className={""}
                    stylegreen={normalstyle}
                    data={
                      uniqueProjectNumbers &&
                      uniqueProjectNumbers.map((projectNo) => ({
                        WI_Project_No: projectNo,
                      }))
                    }
                    stylenormal={normalstyle}
                    valueField={"WI_Project_No"}
                    value={wiobj?.WI_Project_No}
                    labelField={"WI_Project_No"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Project_No: ProjectList.value,
                        WI_Joint_No: "",
                        WI_Welder_Id: "",
                        WI_From_Date: "",
                        WI_To_Date: "",
                        WI_Slip_Status: "",
                      }));
                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Project Number</label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    // className="inspdrop"
                    id="WI_Joint_No"
                    name="WI_Joint_No"
                    className={""}
                    stylegreen={normalstyle}
                    data={getJointNosForSelectedProject()}
                    stylenormal={normalstyle}
                    valueField={"value"}
                    value={wiobj?.WI_Joint_No}
                    labelField={"label"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Joint_No: ProjectList.value,
                        WI_Welder_Id: "",
                      }));
                      fetchwidata(wiobj?.WI_Project_No, ProjectList.value)   

                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Joint</label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    id="WI_Welder_Id"
                    name="WI_Welder_Id"
                    className={""}
                    stylegreen={normalstyle}
                    data={getWelderForSelectedProject()}
                    stylenormal={normalstyle}
                    valueField={"value"}
                    value={wiobj?.WI_Welder_Id}
                    labelField={"label"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Welder_Id: ProjectList.value,
                      }));

                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Welder Code</label>
              </Form.Group>
            </Col>

            <Col md={3}>
              <Date_Input
                divClass="Date-input w-100"
                label="Slip Creation Date From"
                className="groove_datecolor"
                name="WI_From_Date"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate}
                obj={wiobj}
                setobj={setwiobj}
                // datevalue={datevalue}
                // setdatevalue={setdatevalue}
                todate={wiobj.WI_To_Date}
                setError={setError}
                status={error.WI_From_Date.status}
                message={error.WI_From_Date.message}
              />
            </Col>

            <Col md={3} style={{position:"relative" , right:"3px"}}>
              <Date_Input
                divClass="Date-input w-100"
                label="Slip Creation Date To"
                className="groove_datecolor"
                name="WI_To_Date"
                sx={{ fontSize: "6px", fontFamily: "Work Sans", zIndex: "0" }}
                todayDate={todayDate}
                obj={wiobj}
                fromdate={wiobj.WI_From_Date}
                setobj={setwiobj}
                // setdatevalue={setdatevalue}
                // datevalue={datevalue}
                setError={setError}
                status={error.WI_To_Date.status}
                message={error.WI_To_Date.message}
              />
            </Col>

            <Col md={3}>
              <Form.Group className="ListW-input-select">
                <div className="search-select">
                  <SearchSelectWIProject
                    id="WI_Slip_Status"
                    name="WI_Slip_Status"
                    className={""}
                    stylegreen={normalstyle}
                    data={Slipstatus}
                    stylenormal={normalstyle}
                    valueField={"value"}
                    value={wiobj?.WI_Slip_Status}
                    labelField={"name"}
                    placeholder=""
                    onChange={(ProjectList) => {
                      setwiobj((prev) => ({
                        ...prev,
                        WI_Slip_Status: ProjectList.value,
                      }));
                    }}
                  ></SearchSelectWIProject>
                </div>
                <label>Slip Status</label>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <Button
              onClick={handleSubmit}
              className="btn-add-color semiBoldToggleButton rightAlignedButton"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>

      <ToastContainer
        className="toast-position"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
});

export default List_Of_Welders;
